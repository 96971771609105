import React, { useRef, useEffect } from 'react';

export default function Video({ seek = null, play = false, ...props }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video && seek && seek >= 0) {
      video.currentTime = seek; // Seek to the specified seek
    }
  }, [seek]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (play) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, [play]);

  return (
    <video ref={videoRef} {...props} />
  );
}
