import * as XLSX from 'xlsx';

// Function to convert Excel file to array of arrays with validation and callbacks
export const readExcelFile = (...inputs) => {
    const file = inputs[0]
    const onSuccess = inputs[1]
    const onError = inputs.length >= 6 ? inputs[2] : () => {console.log("There was an error with the excel file")}

    return new Promise((resolve, reject) => {
        // Check if the file exists and is an Excel file (extensions .xls or .xlsx)
        const validExtensions = ['.xls', '.xlsx'];
        const fileName = file?.name?.toLowerCase();
        const fileExtension = fileName?.slice(fileName.lastIndexOf('.'));

        if (!file || !validExtensions.includes(fileExtension)) {
        const errorMessage = 'Invalid file type. Please upload a valid Excel file.';
        onError && onError(errorMessage);
        return reject(new Error(errorMessage));
        }

        const reader = new FileReader();

        // On file load
        reader.onload = (e) => {
        const binaryStr = e.target.result;

        try {
            // Parse the binary string into a workbook
            const workbook = XLSX.read(binaryStr, { type: 'binary' });

            // Get the first sheet
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the sheet to an array of arrays
            const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
                  .filter(row => row.some(cell => cell !== undefined && cell !== ""));

            // Resolve the promise with the array of arrays and trigger the success callback
            onSuccess && onSuccess(sheetData);
            resolve(sheetData);
        } catch (error) {
            // Trigger the error callback and reject the promise
            onError && onError(error.message);
            reject(error);
        }
        };

        // Handle file reading errors
        reader.onerror = (error) => {
        onError && onError(error.message);
        reject(error);
        };

        // Read the file as a binary string
        reader.readAsBinaryString(file);
    });
};