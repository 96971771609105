import { default as Container } from './elements/container'
import { default as Flex } from './elements/flex'
import { default as Text } from './elements/text'
import { default as Input } from './elements/input'
import { default as TextArea } from './elements/textarea'
import { default as Button } from './elements/button'
import { default as Image } from './elements/image'
import { default as Video } from './elements/video'
import { default as Dropdown } from './elements/dropdown'
import { default as SvgContainer } from './elements/svgcontainer'
import { default as SvgRect } from './elements/svgrect'
import { default as SvgCircle } from './elements/svgcircle'
import { default as SvgEllipse } from './elements/svgellipse'
import { default as SvgLine } from './elements/svgline'
import { default as SvgPolyline } from './elements/svgpolyline'
import { default as SvgPolygon } from './elements/svgpolygon'
import { default as SvgPath } from './elements/svgpath'
import { default as Anime } from './elements/anime'
import { default as JsonEditor } from './elements/jsoneditor'
import { default as Calendar } from './elements/calendar'
import { default as Upload } from './elements/upload'
import { default as Chart } from './elements/chart'
import { default as Table } from './elements/table'
import { default as IframeComponent } from './elements/iframe'
import { default as DateInput } from './elements/dateinput'
import { default as Html5QrcodePlugin } from './elements/qrcodescanner'
import { default as VirtualList } from './elements/virtuallist'
import { default as SceneRenderer} from './elements/three-fiber'

export const all_components = {
  container: Container,
  flex: Flex,
  text: Text,
  input: Input,
  textarea: TextArea,
  button: Button,
  image: Image,
  video: Video,
  dropdown: Dropdown,
  svgcontainer : SvgContainer,
  svgrect: SvgRect,
  svgcircle: SvgCircle,
  svgellipse: SvgEllipse,
  svgline: SvgLine,
  svgpolyline: SvgPolyline,
  svgpolygon: SvgPolygon,
  svgpath: SvgPath,
  anime: Anime,
  jsoneditor: JsonEditor,
  calendar: Calendar,
  upload: Upload,
  chart: Chart,
  table: Table,
  iframe: IframeComponent,
  dateinput: DateInput,
  qrcodescanner: Html5QrcodePlugin,
  virtuallist: VirtualList,
  three: SceneRenderer
}