// import { Html5QrcodeScanner, Html5Qrcode } from 'html5-qrcode';
// import { useEffect } from 'react';

// const qrcodeRegionId = "html5qr-code-full-region";

// // Creates the configuration object for Html5QrcodeScanner.
// const createConfig = (props) => {
//     let config = {};
//     if (props.fps) {
//         config.fps = props.fps;
//     }
//     if (props.qrbox) {
//         config.qrbox = props.qrbox;
//     }
//     if (props.aspectRatio) {
//         config.aspectRatio = props.aspectRatio;
//     }
//     if (props.disableFlip !== undefined) {
//         config.disableFlip = props.disableFlip;
//     }
//     return config;
// };

// const Html5QrcodePlugin = (props) => {

//     useEffect(() => {
//         // when component mounts
//         const config = createConfig(props);
//         const verbose = props.verbose === true;
//         // Suceess callback is required.
//         if (!(props.qrCodeSuccessCallback)) {
//             throw "qrCodeSuccessCallback is required callback.";
//         }
//         const html5QrcodeScanner = new Html5Qrcode(qrcodeRegionId);
//         // html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

//         html5QrcodeScanner
//         .start(
//             { facingMode: "environment" },
//             config,
//             props.qrCodeSuccessCallback
//         )
//         .then(() => {
//             // const oldRegion = document.getElementById("qr-shaded-region");
//             // if (oldRegion) oldRegion.innerHTML = "";
//         });

//         // cleanup function when component will unmount
//         return () => {
//             html5QrcodeScanner.clear().catch(error => {
//                 console.error("Failed to clear html5QrcodeScanner. ", error);
//             });
//         };
//     }, []);

//     return (
//         <div id={qrcodeRegionId} style={props.style?props.style:{}} />
//     );
// };

// export default Html5QrcodePlugin;

import { useEffect, useState, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props, id) => {
    let config = {};

    const container_node = document.getElementById(id);

    config.fps = props.fps!=null ? props.fps : 20;
    
    config.qrbox = props.qrbox!=null ? props.qrbox*container_node.offsetWidth : 0.7*container_node.offsetWidth;
    
    config.aspectRatio = props.aspectRatio!=null ? props.aspectRatio : 1;
    
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }else{
        config.disableFlip = false;
    }

    console.log("config: ", config)

    return config;
};

const Html5QrcodePlugin = (props) => {
    const [cameras, setCameras] = useState([]);
    const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
    const [isScannerRunning, setIsScannerRunning] = useState(false);
    const isScannerRunningRef = useRef(isScannerRunning);

    const initial = useRef(true);
    const html5QrcodeScannerRef = useRef(null);
    const qrcodeRegionId = props.id ? props.id : "html5qr-code-full-region";

    useEffect(() => {
        console.log("isScannerRunning updated:", isScannerRunning);
        isScannerRunningRef.current = isScannerRunning
    }, [isScannerRunning]);

    const startCamera = (cameraId) => {
        if (html5QrcodeScannerRef.current && !isScannerRunning) {
            html5QrcodeScannerRef.current.start(cameraId, createConfig(props, qrcodeRegionId), props.qrCodeSuccessCallback)
            .then(() => {setIsScannerRunning(true)})
            .catch(err => {
                console.error("Failed to start camera: ", err);
                setIsScannerRunning(false);
            });
        }
    };

    const cameraCleanup = () => {
        console.dir(html5QrcodeScannerRef.current)
        console.log(isScannerRunning)
        if (html5QrcodeScannerRef.current && isScannerRunningRef.current) {
          html5QrcodeScannerRef.current.stop()
            .then(() => {
              console.log("Camera stopped successfully.");
              return html5QrcodeScannerRef.current.clear();
            })
            .then(() => {
              console.log("Scanner cleared successfully.");
            })
            .catch(err => {
              console.error("Error stopping/clearing the camera:", err);
            })
            .finally(() => {
              html5QrcodeScannerRef.current = null;
              setIsScannerRunning(false);
            });
        }
    };

    useEffect(() => {
        html5QrcodeScannerRef.current = new Html5Qrcode(qrcodeRegionId);
    
        Html5Qrcode.getCameras().then(devices => {
            if (devices && devices.length) {
                setCameras(devices);
    
                if (initial.current) {
                    initial.current = false;
                    setCurrentCameraIndex(devices.length - 1);
                } else {
                    startCamera(devices[currentCameraIndex].id);
                    console.log('not initial', String(initial.current));
                }
            }
        });
    
        return ()=>{}
    }, [currentCameraIndex]);

    useEffect(() => {
    
      return cameraCleanup
    }, [])
    

    const cycleCamera = () => {
        if (html5QrcodeScannerRef.current && isScannerRunning) {
            html5QrcodeScannerRef.current.stop()
            .then(() => {
                setIsScannerRunning(false);
                const nextCameraIndex = (currentCameraIndex + 1) % cameras.length;
                setCurrentCameraIndex(nextCameraIndex); // Update the camera index for the next start
            })
            .catch(err => console.error("Failed to stop camera: ", err))
            .finally(() => {
                // Attempt to start the next camera after stopping has been handled
                if (!isScannerRunning) {
                    startCamera(cameras[currentCameraIndex].id);
                    setIsScannerRunning(true);
                }
            });
        }
    };

    return (
        <div style={{ position: 'relative', ...props.style }}>
            <div id={qrcodeRegionId} />
            {cameras.length > 1 && (
                <div
                    style={{
                        position: 'absolute',
                        top: '.8rem',
                        right: '.8rem',
                        zIndex: 2,
                        color: 'inherit',
                        width: '1.5rem'
                    }}
                    onClick={cycleCamera}
                >
                    <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"></path>
                    </svg>
                </div>
            )}
        </div>
    );
};

export default Html5QrcodePlugin;
