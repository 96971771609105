export const getStorage = (...inputs) => {
  const storage_name = inputs[0]
  const storage_mode = inputs.length >= 5 ? inputs[1] : "session"

  let result
  if(storage_mode === "session"){
    try{
      result = JSON.parse(sessionStorage.getItem(storage_name))
    }catch(error){
      result = sessionStorage.getItem(storage_name)
    }
    // console.log("getStorage", storage_name, storage_mode, result);
    return result
  }
  if(storage_mode === "local"){
    try{
      result = JSON.parse(sessionStorage.getItem(storage_name))
    }catch(error){
      result = sessionStorage.getItem(storage_name)
    }
    // console.log("getStorage", storage_name, storage_mode, result);
    return result
  }
  return null;
}

export const setStorage = (...inputs) => {
  const storage_name = inputs[0]
  const storage_value = inputs[1]
  const storage_mode = inputs.length >= 6 ? inputs[2] : "session"

  if(storage_mode === "session"){
    sessionStorage.setItem(storage_name, JSON.stringify(storage_value))
    // console.log("setStorage", storage_name, storage_mode, storage_value);
    return storage_value
  }
  if(storage_mode === "local"){
    localStorage.setItem(storage_name, JSON.stringify(storage_value))
    // console.log("setStorage", storage_name, storage_mode, storage_value);
    return storage_value
  }
  return null;
}

export const removeStorage = (...inputs) => {
  const storage_name = inputs[0]
  const storage_mode = inputs.length >= 5 ? inputs[1] : "session"

  if(storage_mode === "session"){
    sessionStorage.removeItem(storage_name)
    // console.log("removeStorage", storage_name, storage_mode);
  }
  if(storage_mode === "local"){
    localStorage.removeItem(storage_name)
    // console.log("removeStorage", storage_name, storage_mode);
  }
}

export const clearStorage = (...inputs) => {
  const storage_mode = inputs.length >= 4 ? inputs[0] : "session"

  if(storage_mode === "session"){
    sessionStorage.clear()
    // console.log("clearStorage", storage_mode);
  }
  if(storage_mode === "local"){
    localStorage.clear()
    // console.log("clearStorage", storage_mode);
  }
}