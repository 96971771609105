// SceneRenderer.jsx
import React from 'react';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';

const geometryFactory = (type, dimensions) => {
  const map = {
    BoxGeometry: THREE.BoxGeometry,
    CapsuleGeometry: THREE.CapsuleGeometry,
    CircleGeometry: THREE.CircleGeometry,
    ConeGeometry: THREE.ConeGeometry,
    CylinderGeometry: THREE.CylinderGeometry,
    DodecahedronGeometry: THREE.DodecahedronGeometry,
    EdgesGeometry: THREE.EdgesGeometry,
    ExtrudeGeometry: THREE.ExtrudeGeometry,
    IcosahedronGeometry: THREE.IcosahedronGeometry,
    LatheGeometry: THREE.LatheGeometry,
    OctahedronGeometry: THREE.OctahedronGeometry,
    PlaneGeometry: THREE.PlaneGeometry,
    PolyhedronGeometry: THREE.PolyhedronGeometry,
    RingGeometry: THREE.RingGeometry,
    ShapeGeometry: THREE.ShapeGeometry,
    SphereGeometry: THREE.SphereGeometry,
    TetrahedronGeometry: THREE.TetrahedronGeometry,
    TorusGeometry: THREE.TorusGeometry,
    TorusKnotGeometry: THREE.TorusKnotGeometry,
    TubeGeometry: THREE.TubeGeometry,
    WireframeGeometry: THREE.WireframeGeometry
  };

  const Constructor = map[type];
  return Constructor ? new Constructor(...(dimensions || [])) : null;
};

const GeometryObject = ({ config }) => {
  const geometry = geometryFactory(config.type, config.dimensions);
  if (!geometry) return null;

  const material = new THREE.MeshStandardMaterial(config.material || { color: '#cccccc' });

  return (
    <mesh
      geometry={geometry}
      material={material}
      position={config.position || [0, 0, 0]}
      rotation={config.rotation || [0, 0, 0]}
      scale={config.scale || [1, 1, 1]}
    />
  );
};

const LightObject = ({ config }) => {
  switch (config.type) {
    case 'ambient':
      return <ambientLight color={config.color} intensity={config.intensity} />;
    case 'point':
      return <pointLight color={config.color} intensity={config.intensity} position={config.position} />;
    case 'directional':
      return <directionalLight color={config.color} intensity={config.intensity} position={config.position} />;
    default:
      return null;
  }
};

const SceneRenderer = ({ config }) => {
  const { camera, lights, objects } = config;

  return (
    <Canvas
      camera={{
        position: camera?.position || [0, 0, 5],
        fov: camera?.fov || 75,
        near: camera?.near || 0.1,
        far: camera?.far || 1000
      }}
    >
      {(lights || []).map((light, i) => (
        <LightObject key={i} config={light} />
      ))}
      {(objects || []).map((obj, i) => (
        <GeometryObject key={i} config={obj} />
      ))}
    </Canvas>
  );
};

export default SceneRenderer;
