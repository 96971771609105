import React from "react";
import ReactMarkdown from 'react-markdown'
import DOMPurify from 'dompurify';
// import styled from 'styled-components';

export default function Text(props) {
  const { tag, text } = props;
  delete props["text"];
  delete props["tag"];
  delete props["dangerouslySetInnerHTML"];

  if (tag === "h1") {
    return <h1 {...props}>{text}</h1>;
  }
  if (tag === "h2") {
    return <h2 {...props}>{text}</h2>;
  }
  if (tag === "h3") {
    return <h3 {...props}>{text}</h3>;
  }
  if (tag === "p") {
    return <p {...props}>{text}</p>;
  }
  if (tag === "a") {
    return <a {...props}>{text}</a>;
  }
  if (tag === "html") {
    const sanitizedText = DOMPurify.sanitize(text);
    // console.log("html: ", text, typeof text, props, sanitizedText)
  
    return (
      <div {...props} dangerouslySetInnerHTML={{ __html: sanitizedText }} />
    );
  }
  if (tag === "md") {
    // console.log("react-markdown: ", text, typeof text, props)
    return <ReactMarkdown {...props}>{text}</ReactMarkdown>;
  }

  return <p {...props}>{text}</p>;
}

// const Text = styled(UnstyledText)`
// ${props => props.cssString}
// `
// export default Text;