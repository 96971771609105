import React, { Lazy, useRef, useState } from 'react';
import styled from 'styled-components';

function UnstyledUpload(props) {
  let {onUpload, placeholder, style, accept, ...filtered_props} = props
  onUpload = props.onUpload != null && props.onUpload instanceof Function ? props.onUpload : (file) => {console.log(`File: ${file.name} | ${file.type} | ${(file.size / 1024).toFixed(2)}KB`)}
  placeholder = props.placeholder != null ? props.placeholder : 'Drag & Drop or Click to Upload'
  
  const [file, setFile] = useState(null);
  const inputRef = useRef(null)

  // Function to handle file selection
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the first file
    selectedFile.skip_cloneDeep = true
    setFile(selectedFile); // Save file to state
    onUpload(selectedFile)
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    
    if (droppedFile) {
      handleFileChange({ target: { files: [droppedFile] } });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior (prevent file from being opened)
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        accept={accept || undefined}
        style={{ display: 'none' }}
        ref={inputRef}
      />
      <div
        onClick={() => inputRef.current.click()} 
        onDrop={handleDrop} 
        onDragOver={handleDragOver} 
        style={{
          border: '2px dashed #999',
          borderRadius: '10px',
          padding: '50px',
          textAlign: 'center',
          cursor: 'pointer',
          margin: '20px 0',
          backgroundColor: '#f9f9f9',
          ...style
        }}
        {...filtered_props}
      >
        <p>{placeholder}</p>
      </div>
    </div>
  );
};

const Upload = styled(UnstyledUpload)`
${props => props.cssString}
`
export default Upload;