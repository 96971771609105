export const getByClassName = (...inputs) => {
    const elements = Array.from(document.getElementsByClassName(inputs[0]))
    elements.forEach(element => {
        element.skip_parse = true
        element.skip_cloneDeep = true
    });
    // console.log("getByClassName", element)
    return elements
}

export const getById = (...inputs) => {
    const element =  document.getElementById(inputs[0]);
    element.skip_parse = true
    element.skip_cloneDeep = true
    // console.log("getById", element)
    return element
}

export const setByClassName = (...inputs) => {
    const class_name = inputs[0]; // e.g.: animated_button
    const property_location = inputs[1]; // e.g.: ['style', 'width']
    const value = inputs[2]; // e.g.: '40px'

    const elements = document.getElementsByClassName(class_name);
    
    for (const element of elements) {
        let currentObject = element;
        
        // Traverse the property_location array to reach the nested property
        for (const property of property_location.slice(0, -1)) {
            currentObject = currentObject[property];
        }
        
        // Set the value of the final property
        currentObject[property_location.slice(-1)[0]] = value;
    }

    return value;
}

export const setById = (...inputs) => {
    const class_name = inputs[0]; // e.g.: animated_button
    const property_location = inputs[1]; // e.g.: ['style', 'width']
    const value = inputs[2]; // e.g.: '40px'

    const element = document.getElementById(class_name);
    
    let currentObject = element;
    
    // Traverse the property_location array to reach the nested property
    for (const property of property_location.slice(0, -1)) {
        currentObject = currentObject[property];
    }
    
    // Set the value of the final property
    currentObject[property_location.slice(-1)[0]] = value;

    return value;
}

export const focusByClassName = (...inputs) => {
    const class_name = inputs[0]; // e.g.: animated_button
    const index_ = inputs.length >= 5 ? inputs[1] : 0; // e.g.: animated_button

    const element = document.getElementsByClassName(class_name);
    
    if (element != null && element.length > 0) {
        return element[index_].focus()
    }

    return null
}


export const scrollIntoView = (...inputs) => {
    const class_name = inputs.length >= 4 ? inputs[0] : null; // e.g.: animated_button
    const options = inputs.length >= 5 ? inputs[1] : null; // e.g.: animated_button
    const index_ = inputs.length >= 6 ? inputs[2] : 0; // e.g.: animated_button

    if (class_name != null) {
        const element = document.getElementsByClassName(class_name);
    }
    
    if (element != null) {
        if (options != null) {
            return element[index_].scrollIntoView(options)
        } else {
            return element[index_].scrollIntoView()
        }
    }

    return null
}